import React from "react"

import "../styles/global.css"

import Navbar from "../components/Navbar"
import MobileNavbar from "../components/MobileNavbar"
import Footer from "../components/Footer"
import ContactFooter from "../components/ContactFooter"
import Whatsapp from "../components/Whatsapp"
import ContactForm from "../components/ContactPage/ContactForm"
import ContactInfo from "../components/ContactPage/ContactInfo"

import Seo from "../components/seo"

const HeaderText = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center my-10">
      <div className="lg:w-3/4 w-10/12 flex flex-col justify-center items-center">
        <div className="2xl:text-5xl xl:text-4xl md:text-2xl text-2xl   font-helvetica font-bold text-text-primary uppercase my-3">
          CONTACT US NOW
        </div>
        <div className="w-3/4 2xl:text-xl md:text-sm text-sm font-thin font-helvetica text-text-primary text-center my-3">
          Do you have any questions or queries? The members of our expert team
          is always happy to help you
        </div>
      </div>
      <div className="lg:w-3/4 w-10/12 flex flex-row justify-center items-center my-8">
        <a
          href="https://wa.me/919899783871?text=Hey, I wanna know about VSN"
          target="_blank"
          rel="noopener"
        >
          <div className="w-auto px-5 btn btn-lg">
            <Whatsapp fixed={false} />

            <p className="text-text-primary uppercase text-xl text-center">
              Chat with us
            </p>
          </div>
        </a>
      </div>
    </div>
  )
}

export default function ContactUs() {
  return (
    <>
      <Seo title="CONTACT US" />
      <Whatsapp />
      <div className="w-full flex flex-col justify-center items-center font-helvetica">
        <div className="md:hidden w-full flex flex-col justify-center items-center font-helvetica">
          <MobileNavbar>
            <HeaderText />
            {/* <ContactForm /> */}
            <ContactInfo />
            <Footer />
            <ContactFooter />
          </MobileNavbar>
        </div>
        <div className="md:visible md:w-full md:flex md:flex-col md:justify-center md:items-center md:font-helvetica hidden">
          <Navbar />
          <HeaderText />
          {/* <ContactForm /> */}
          <ContactInfo />
          <Footer />
          <ContactFooter />
        </div>
      </div>
    </>
  )
}
