import React, { createRef, useEffect, useRef, useState } from "react"

import { useStaticQuery, graphql } from "gatsby"

const Box = ({ country, fill = true, setModalOpen }) => {
  return (
    <div className="md:w-1/2 w-full aspect-square flex justify-center items-center p-5">
      <div
        onClick={() =>
          setModalOpen(country !== "united states" ? country : "us")
        }
        className={`modal-button w-full aspect-square flex justify-center items-center hover:drop-shadow-2xl hover:scale-[1.02] transition-all cursor-pointer p-2 ${
          fill
            ? "bg-text-primary border-2 border-text-primary shadow-card-border"
            : "border-2 border-text-primary bg-white"
        } rounded-lg`}
      >
        <h1
          className={`xl:text-4xl md:text-3xl text-2xl font-bold text-center uppercase ${
            fill ? "text-white" : "text-text-primary"
          }`}
        >
          {country}
        </h1>
      </div>
    </div>
  )
}

const Popup = ({ info, open, setModalOpen, popup }) => {
  const modalRef = useRef()

  const PopupBox = ({ title, content, image, fill }) => {
    return (
      <div className="md:w-1/3 w-full m-1 flex flex-col justify-center items-center ">
        <div
          className={`md:w-10/12 md:h-auto w-full md:aspect-square aspect-auto md:m-1 flex flex-col justify-between items-center md:p-5 p-1 rounded-xl border-2 ${
            fill ? "bg-text-primary" : "border-text-primary bg-white"
          }`}
        >
          <h1
            className={`uppercase ${
              fill ? "text-white" : "text-text-primary"
            } md:text-2xl text-xl text-center font-bold my-2`}
          >
            {title}
          </h1>
          <p
            className={`md:text-lg text-sm text-center ${
              fill ? "text-white" : "text-text-primary"
            } `}
          >
            {content}
          </p>
          <img src={image} alt="title" width={60} />
        </div>
      </div>
    )
  }
  return (
    <div class={`modal ${open ? "modal-open" : ""}`} ref={modalRef}>
      <div class="modal-box h-auto lg:max-w-6xl max-w-6xl flex flex-col justify-center items-center bg-card-footer border-2 border-text-primary">
        <div className="w-full flex md:flex-row flex-col justify-center items-start p-5">
          <PopupBox
            title="call us"
            content={`Phone No. ${info.phone}`}
            image={popup.phone}
          />
          <PopupBox
            title="main office"
            content={`${info.address}`}
            image={popup.office}
            fill={true}
          />
          <PopupBox
            title="email us"
            content={`${info.email}`}
            image={popup.email}
          />
        </div>
        <div class="modal-action">
          <button
            onClick={() => {
              modalRef.current.classList.remove("modal-open")
              setModalOpen("")
            }}
          >
            <img src={popup.close} alt="close" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default function ContactInfo() {
  const data = useStaticQuery(graphql`
    {
      image: allFile(
        filter: {
          relativeDirectory: { eq: "contactPage" }
          name: { eq: "info" }
          extension: { eq: "svg" }
        }
      ) {
        edges {
          node {
            publicURL
          }
        }
      }
      contactInfo: site {
        siteMetadata {
          contactInfo {
            australia {
              address
              email
              phone
            }
            dubai {
              address
              email
              phone
            }
            india {
              address
              email
              phone
            }
            us {
              address
              email
              phone
            }
          }
        }
      }
      popup: allFile(
        filter: {
          relativeDirectory: { eq: "contactPage/popup" }
          extension: { eq: "svg" }
        }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  var { image, contactInfo, popup } = data
  image = image.edges[0].node
  contactInfo = contactInfo.siteMetadata.contactInfo

  const popupImages = popup
  popup = {}
  popupImages.edges.map((edge, i) => {
    popup[edge.node.name] = edge.node.publicURL
  })

  const [modalOpen, setModalOpen] = useState("")

  return (
    <>
      <div className="w-full flex lg:flex-row flex-col-reverse justify-evenly items-center px-10 my-10">
        <div className="lg:w-6/12 w-full flex-col justify-center items-center p-5 m-2 ">
          <div className="md:visible hidden w-full md:flex flex-wrap justify-center items-center">
            <Box country={"india"} setModalOpen={setModalOpen} />
            <Box
              country={"australia"}
              fill={false}
              setModalOpen={setModalOpen}
            />
            <Box country={"dubai"} fill={false} setModalOpen={setModalOpen} />
            <Box country={"united states"} setModalOpen={setModalOpen} />
          </div>

          <div className="md:hidden visible w-full flex flex-wrap justify-center items-center">
            <Box country={"india"} setModalOpen={setModalOpen} />
            <Box
              country={"australia"}
              fill={false}
              setModalOpen={setModalOpen}
            />
            <Box country={"dubai"} setModalOpen={setModalOpen} />
            <Box
              country={"united states"}
              fill={false}
              setModalOpen={setModalOpen}
            />
          </div>
        </div>
        <div className="lg:w-6/12 flex w-full justify-center items-center self-stretch my-5">
          <img src={image.publicURL} alt="info" className="w-10/12" />
        </div>
      </div>
      {Object.keys(contactInfo).map((country, id) => (
        <Popup
          info={contactInfo[country]}
          key={id}
          open={modalOpen == country}
          setModalOpen={setModalOpen}
          popup={popup}
        />
      ))}
    </>
  )
}
